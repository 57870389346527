export const mockedSupplierReport = {
  isLoading: false,
  data: {
    content: [
      {
        supplierId: "2282861032177140998",
        invoiceCount: "1",
        totalSum: {
          amount: "1562.30",
          currency: "SEK",
        },
        firstInvoiceDate: "2023-04-09",
        lastInvoiceDate: "2023-05-07",
      },
      {
        supplierId: "2283478909880108310",
        invoiceCount: "1",
        totalSum: {
          amount: "1443.54",
          currency: "SEK",
        },
        firstInvoiceDate: "2023-04-14",
        lastInvoiceDate: "2023-04-27",
      },
      {
        supplierId: "2318371232984074022",
        invoiceCount: "1",
        totalSum: {
          amount: "395.48",
          currency: "SEK",
        },
        firstInvoiceDate: "2023-04-21",
        lastInvoiceDate: "2023-05-05",
      },
    ],
    pageable: {
      sort: [
        {
          direction: "DESC",
          property: "projected_sum",
          ignoreCase: false,
          nullHandling: "NATIVE",
          ascending: false,
          descending: true,
        },
      ],
      pageNumber: 0,
      pageSize: 25,
      offset: "0",
      unpaged: false,
      paged: true,
    },
    last: true,
    totalElements: "3",
    totalPages: 1,
    sort: [
      {
        direction: "DESC",
        property: "projected_sum",
        ignoreCase: false,
        nullHandling: "NATIVE",
        ascending: false,
        descending: true,
      },
    ],
    first: true,
    number: 0,
    numberOfElements: 3,
    size: 25,
    empty: false,
  },
};
