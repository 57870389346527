import { Box, CircularProgress, Grid } from "@mui/material";
import React from "react";

export const CircularLoader = () => {
  return (
    <>
      <Grid item xs={false} md={1} lg={2} />
      <Grid item xs={12} md={12} lg={12}>
        <Box display="flex" justifyContent="center" margin={4}>
          <CircularProgress />
        </Box>
      </Grid>
    </>
  );
};
